import './App.css';
import './output.css';
import spider from './spider.png';
import {useState} from "react";

function App() {
    const [clicked, setClicked] = useState(false);

    const onClick = () => {
        setClicked(true)
        console.log("click");
        // play a song
        const audio = new Audio("https://www.myinstants.com/media/sounds/dry-fart.mp3");
        audio.play().then(r => console.log("played"));

        // wait 1 seconds
        setTimeout(() => {
            setClicked(false)
        }, 500);
    }

    return (
        <>
            <div className="w-full h-full mt-40">
                <div className="w-full h-[100%] flex justify-center">
                    <div>
                        <header className="text-center">
                            <h1 className="text-3xl text-white font-bold">Spider Jai peté</h1>
                            <h2 className="text-xl text-gray-400">Accronyme de chatgpt ta capté</h2>
                        </header>
                        <main className="py-40 items-center flex justify-center">
                            <img onClick={onClick} src={spider} height={800} width={800}
                                 className={"duration-200 md:w-1/3 cursor-pointer " + (clicked ? "w-1/4" : "")}
                                 alt={"Ya pu"}></img>
                        </main>
                        <footer className={"mb-36"}>
                            <p className="text-center text-gray-400">Fais avec ❤️ par Jules</p>
                        </footer>
                    </div>
                </div>
            </div>
        </>
    );
}

export default App;
